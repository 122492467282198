.faq-button-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.faq-button-container button {
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  border-radius: 20px;
  background-color: #FFF;
  color: black;
  padding: 4px 28px;
  transition: 300ms ease-in-out;
  border: 1px solid #FFF;
  font-size: 0.9rem;
  margin-left: 2vw;
  margin-top: 1vh;
}

.faq-button-container button:active {
  transform: scale(0.95);
}

.faq-button-container button:focus {
  outline: none;
}

.faq-button-container button:hover {
  background-color: #5aa4eb;
  border: 1px solid #5aa4eb;
  color: #FFF;
}

.landing-page-main-container {
  margin-top: 6vh;
  color:#FFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 4px;
  font-size: 1.7rem;
}

.landing-header-container img {
  height: 19vh;
  margin-left: 1vw;
}

.landing-subtext-container {
  font-family: sans-serif;
  font-size: 1.3rem;
  margin-bottom: 5vh;
  letter-spacing: 0.15px;
}

.landing-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17vw;
  padding: 10px;  
  cursor: pointer;
  background-color: #5aa4eb;
  font-family: sans-serif;
  border-radius: 20px;
  transition: all .4s ease;
}

.landing-button-container:hover{
  background-color: #098ada
}

.landing-button-container span{
  font-size: 1rem;
  margin-left: 1vw;
  font-weight: bold;
}

.landing-button-container img {
  width: 2vw;
}

/* Large Monitor */
@media (min-width: 1515px) and (min-height: 805px) {
  .faq-button-container button {
    padding: 4px 30px;
    font-size: 0.95rem;
    margin-top: 1.5vh;
  }

  .landing-page-main-container {
    margin-top: 6.5vh;
  }

  .landing-header-container {
    font-size: 1.8rem;
  }

  .landing-header-container img {
    height: 17.5vh;
  }
  
  .landing-subtext-container {
    margin-top: 0.5vh;
    margin-bottom: 4.5vh;
    font-size: 1.375rem;
    letter-spacing: 0.3px;
  }

  .landing-button-container span{
    font-size: 1.05rem;
  }
}

/* Gettin smaller! */
@media (max-width: 1010px) {
  .landing-header-container {
    font-size: 1.6rem;
  }
  
  .landing-header-container img {
    height: 115px;
  }

  .landing-subtext-container {
    font-size: 1.25rem;
  }

  .landing-button-container {
    width: 25vw;
  }
}

/* Gettin smaller! */
@media (max-width: 620px) {
  .landing-header-container {
    font-size: 1.2rem;
  }
  
  .landing-header-container img {
    height: 95px;
  }

  .landing-subtext-container {
    text-align: center;
    font-size: 1.2rem;
  }

  .landing-button-container {
    width: 38vw;
  }
}

/* iPad */
@media (min-height: 1150px) {
  .landing-header-container {
    margin-top: 100px;
    font-size: 1.8rem;
  }
  
  .landing-header-container img {
    height: 130px;
  }

  .landing-subtext-container {
    font-size: 1.4rem;
  }
}

/* Phone */
@media (max-width: 420px) {
  .landing-header-container {
    margin-top: 12vh;
    letter-spacing: 2px;
    font-size: 1.05rem;
    margin-bottom: 10px;
  }
  
  .landing-header-container img {
    height: 8.5vh;
    margin-left: 10px;
  }
  
  .landing-subtext-container {
    font-family: sans-serif;
    font-size: 1.17rem;
    margin-bottom: 5vh;
    text-align: center;
  }
  
  .landing-button-container {
    width: 215px;
    padding: 8px;
  }

  .landing-button-container span{
    margin-left: 2vw;
    font-weight: bold;
  }
  
  .landing-button-container img {
    width: 5vw;
  }

  .faq-button-container button {
    padding: 2px 22px;
    font-size: 0.75rem;
  }
}

/* Mid Phone */
@media (max-width: 420px) and (max-height: 845px) {
  .landing-header-container {
    font-size: 1.025rem;
  }

  .landing-header-container img {
    height: 8vh;
  }
}

/* Smaller Phone */
@media (max-width: 370px) and (max-height: 625px){
  .landing-header-container {
    font-size: 1rem;
  }

  .landing-header-container img {
    height: 7vh;
  }

  .landing-subtext-container {
    font-size: 1.1rem;
    letter-spacing: 0.2px;
  }
}

/* Smallest Phone */
@media (max-width: 360px) {
  .landing-header-container img {
    display: none;
  }
}

/* Bye Bye mr ape logo */
@media (max-width: 400px) {
  .landing-header-container {
    font-size: 1.05rem;
  }

  .landing-header-container img {
    display: none;
  }
}
