.back-button-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
  
.back-button-container button {
    text-transform: uppercase;
    font-family: sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    border-radius: 20px;
    background-color: #FFF;
    color: black;
    padding: 4px 22px;
    transition: 300ms ease-in-out;
    border: 1px solid #FFF;
    font-size: 0.9rem;
    margin-left: 2vw;
    margin-top: 1vh;
}
  
.back-button-container button:active {
    transform: scale(0.95);
}
  
.back-button-container button:focus {
    outline: none;
}
  
.back-button-container button:hover {
    background-color: #5aa4eb;
    border: 1px solid #5aa4eb;
    color: #FFF;
}

.FAQ {
    color: #ffff;
}

.faq-headline-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat';
    letter-spacing: 4px;
    font-size: 1.1rem;
}

.overall-qa-container {
    display: flex;
}

.faq-dummy1 {
    flex: 1;
}

.faq-dummy2 {
    flex: 1;
}

.qa-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1.75;
}

.qa-container span {
    font-family: sans-serif;
    font-size: 1.12rem;
}

.question {
    margin-bottom: 5px;
}

.faq-padding {
    margin-bottom: 35px;
}

.qa-twitter-blue {
    color:  #7db6eb;
}

/* Large Monitor */
@media (min-width: 1515px) and (min-height: 805px) {
    .qa-container {
        flex: 1.7;
    }

    .back-button-container button {
        padding: 4px 23px;
        font-size: 0.95rem;
        margin-top: 1.5vh;
    }

    .faq-headline-container {
        font-size: 1.15rem;
    }
}


/* Gettin smaller! */
@media (max-width: 1010px) {
    .qa-container {
        flex: 3;
    }

    .qa-container span {
        font-family: sans-serif;
        font-size: 1.1rem;
    }
}
  
  /* Gettin smaller! */
  @media (max-width: 620px) {
    .qa-container {
        flex: 7;
    }

    .qa-container span {
        font-family: sans-serif;
        font-size: 1.07rem;
    }
}
  

/* Phone */
@media (max-width: 420px) {
    .faq-headline-container {
        font-size: 0.95rem;
    }

    .qa-container {
        flex: 10;
    }

    .qa-container span {
        font-family: sans-serif;
        font-size: 1rem;
    }

    .back-button-container button {
        padding: 2px 18px;
        font-size: 0.75rem;
    }

    .faq-headline-container {
        margin-bottom: 6px;
    }
}
