.headline-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.headline-container span {
    margin-top: -1vh;
    font-size: 1.55rem;
    margin-bottom: 3vh;
    font-family: sans-serif;
}

.summary-stats {
    display: flex;
}

.dummy1 {
    flex: 1;
}

.dummy2 {
    flex: 1;
}

.summary-stats-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1.7;
    background-color: #f5f5f5;
    color: black;
    padding: 20px;
    border-radius: 30px;
    box-shadow: #f5f5f5 0px 2px 8px 0px;
}

.summary-stats-container a {
    color: #5aa4eb;
    text-decoration: underline;
}

.summary-stats-container span {
    font-size: 1.4rem;
    margin-bottom: 4.75vh;
    font-family: sans-serif;
}

.not-showing-all-users-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6.5vh;
}

.not-showing-all-users-container span {
    font-size: 1.55rem;
}

.summary-stats-container p {
    font-size: 1.4rem;
    margin: 0;
    font-family: sans-serif;
}

/* Large Monitor */
@media (min-width: 1515px) and (min-height: 805px) {
    .summary-stats-container {
        flex: 1.425;
    }

    .summary-stats-container span {
        font-size: 1.5rem;
    }
    
    .summary-stats-container p {
        font-size: 1.5rem;
    }
}

@media (min-width: 1850px) and (min-height: 825px) {
    .summary-stats-container {
        flex: 1.25;
    }
}

/* Gettin smaller! */
@media (max-width: 1010px) {
    .not-showing-all-users-container span {
        font-size: 1.45rem;
        text-align: center;
    }
    
    .summary-stats-container {
        flex: 8;
    }
    
    .summary-stats-container span {
        font-size: 1.25rem;
        margin-bottom: 4.5vh;
    } 

    .summary-stats-container p {
        font-size: 1.25rem;
    }   
}

/* Phone */
@media (max-width: 420px) {
    .not-showing-all-users-container span  {
        font-size: 1.3rem;
        text-align: center;
    }

    .summary-stats-container {
        flex: 8;
        margin-top: 0.5vh;
    }

    .summary-stats-container span {
        font-size: 1.14rem;
        margin-bottom: 4.5vh;
    }

    .summary-stats-container p {
        font-size: 1.14rem;
    }

    .headline-container span {
        font-size: 1.4rem;
    }
}

@media (max-width: 375px) {
    .remove-for-small-phone {
        display: none;
        visibility: hidden;
    }
}
